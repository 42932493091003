import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          login: "Login",
          loginPage: "Login Into Your Account",
          username: "Email or Username",
          pass: "Password",
          email: "Email",
          errorMessage: "Invalid Username or Password",
          errorMessageField: "Please fill all fields",
          forgetPass: "Forget your password?",
          rememberMe: "Remember me",
          noAccount: "Don't have an account?",
          haveAccount: "Already have an account?",
          register: "Register",
          newPassword: "New Password",
          confirmPassword: "Confirm Password",
          logout: "Logout",
          dashboard: "Dashboard",
          dragAndDrop: "Click to browse or Drag and drop",
          startRecording: "Start recording here",
          next: "Next",
          previous: "Previous",
          library: "Library",
          feedbacks: "Feedbacks",
          recording: "Recording...",
          date: "Date",
          search: "Search...",
          customers: "Customers",
          editCustomer: "Edit Customer",
          profile: "Profile",
          AccountDetails: "Account Details",
          contacts: "Contacts",
          edit: "Edit",
          upload: "Upload",
          deleteCustomer: "Delete Customer",
          modalMessage: "Are you sure about delete this?",
          delete: "Delete",
          cancel: "Cancel",
          handleSubmit: "Submit",
          actions: "Actions",
          category: "Category",
          backToHome: "Back to Main Page",
          notFoundMsg: "Page Not Found!",
          selectPicture: "Select a picture",
          chooseName: "Choose a name",
          drum: "Drum",
          guitar: "Guitar",
          piano: "Piano",
          chooseInstrument: "Choose an instrument",
          instrument: "Instrument",
          name: "Name",
          validate: "Validate",
          title: "Title",
          reportABug: "Report a bug",
        },
      },
      fr: {
        translation: {
          login: "Se connecter",
          loginPage: "Se connecter à votre compte",
          username: "Email ou Nom d'utilisateur",
          pass: "Mot de passe",
          email: "Email",
          errorMessage: "Nom d'utilisateur ou mot de passe invalide",
          errorMessageField: "Veuillez remplir tous les champs",
          forgetPass: "Oublié votre mot de passe ?",
          rememberMe: "Se souvenir de moi",
          noAccount: "Vous n'avez pas de compte ?",
          haveAccount: "Vous avez déjà un compte ?",
          register: "S'inscrire",
          newPassword: "Nouveau mot de passe",
          confirmPassword: "Confirmer le mot de passe",
          logout: "Déconnexion",
          dashboard: "Tableau de bord",
          dragAndDrop: "Cliquez pour parcourir ou Glisser-déposer",
          startRecording: "Commencer l'enregistrement ici",
          next: "Suivant",
          previous: "Précédent",
          library: "Librarie",
          feedbacks: "Retours",
          recording: "Enregistrement...",
          date: "Date",
          search: "Rechercher...",
          customers: "Clients",
          editCustomer: "Modifier le client",
          profile: "Profile",
          AccountDetails: "Détails du compte",
          contacts: "Contacts",
          edit: "Modifier",
          upload: "Mettre à jour",
          deleteCustomer: "Supprimer le client",
          modalMessage: "Êtes-vous sûr de vouloir supprimer ceci ?",
          delete: "Supprimer",
          cancel: "Annuler",
          handleSubmit: "Soumettre",
          actions: "Actions",
          category: "Catégorie",
          backToHome: "Retour à la page principale",
          notFoundMsg: "Page non trouvée !",
          selectPicture: "Sélectionner une image",
          chooseName: "Choisir un nom",
          drum: "Batterie",
          guitar: "Guitare",
          piano: "Piano",
          chooseInstrument: "Choisir un instrument",
          instrument: "Instrument",
          name: "Nom",
          validate: "Valider",
          title: "Titre",
          reportABug: "Signaler un bug",
        },
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;