import React, { useContext, useRef, useState } from "react";

import axios from "axios"; // Import Axios or your preferred HTTP client library
import langContextObj from "../../store/langContext";
import { images } from "../../constants";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import { useTranslation } from "react-i18next";
import classes from "./Register.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component';

interface RegisterBetaBoxProps {
  email: string;
  code: string;
}

function RegisterBetaBox({ email, code }: RegisterBetaBoxProps) {
  const langCtx = useContext(langContextObj);
  const userNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const errorMessageRef = useRef<HTMLSpanElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);


  let isValid = true;


  async function registerHandler(e: React.FormEvent) {
    e.preventDefault();

    const username = userNameRef.current?.value;
    const password = passwordRef.current?.value;

    if (!username || !password) {
      if (!username) {
        userNameRef.current?.focus();
        errorMessageRef.current?.setAttribute(
          "style",
          "display: inline-block;opacity: 1"
        );
        isValid = false;
        setError(t("errorMessageField"));
      }

      if (!password) {
        passwordRef.current?.focus();
        errorMessageRef.current?.setAttribute(
          "style",
          "display: inline-block;opacity: 1"
        );
        isValid = false;
        setError(t("errorMessageField"));
      }

      return;
    }

    try {
      const response = await axios.post("/api/users/register-beta", {
        "email": email,
        "oldPassword": code,
        "password": password,
        "username": username,
        "extendedToken": true,
      });

      if (response.status === 201) {
        navigate("/login");
        Store.addNotification({
          title: "Account Created",
          message: "Congratulations! Your account has been successfully created.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    } catch (error: any) {
      if (error.response && error.response.data === "User already registered") {
        console.log('User already registered');
        userNameRef.current.focus();
        errorMessageRef.current?.setAttribute(
          "style",
          "display: inline-block;opacity: 1"
        );
        setError("User already registered.");
      } else if (error.response && error.response.data === "Invalid data") {
        passwordRef.current.focus();
        errorMessageRef.current?.setAttribute(
          "style",
          "display: inline-block;opacity: 1"
        );
        setError("Password must be at least 12 characters with uppercase, lowercase, digit, and special character among !@#$%^&*.");
      } else {
        console.log('An error occurred while processing the request.', error);
        Store.addNotification({
          title: "Internal Server Error",
          message: "An error occurred while processing the request.. Please try again later.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
    }
  }

  return (
    <div className={`${classes.container}`}>
      <div className={classes.registerBox}>
        <div className={classes.logo}>
          <img src={images.logo} alt="digikala" />
        </div>
        <form onSubmit={registerHandler}>
          <Input
            type={"text"}
            id={"email"}
            placeholder={"Email"}
            value={email}
            readonly={true}
          />
          <Input
            ref={userNameRef}
            type={"text"}
            id={"username"}
            placeholder={"Username"}
          />
          <Input
            ref={passwordRef}
            type={"password"}
            id={"pass"}
            placeholder={"Password"}
          />
          <span ref={errorMessageRef} className={classes.errorMessage}>
            {error}
          </span>
          <Button type="submit">{t("register")}</Button>
          <Link className={classes.forgat_pass} to="/">
            {t("haveAccount")}
          </Link>
        </form>
      </div>
    </div>
  );
}

export default RegisterBetaBox;
