import React from "react";
import { useLocalStorage } from "usehooks-ts";

type TContext = {
  isLogin: boolean;
  token: string | null;
  userRole: string | null;
  toggleLogin: () => void;
  setTokenValue: (token: string | null) => void;
  setUserRoleValue: (role: string | null) => void;
};

const LoginContext = React.createContext<TContext>({
  isLogin: false,
  token: null,
  userRole: null,
  toggleLogin: () => { },
  setTokenValue: (token: string | null) => { },
  setUserRoleValue: (userRole: string | null) => { },
});

export const LoginContextProvider: React.FC = (props) => {
  const [isLogin, setIsLogin] = useLocalStorage("isLogin", false);
  const [token, setToken] = useLocalStorage<string | null>("token", null);
  const [userRole, setUserRole] = useLocalStorage<string | null>("userRole", null);

  function toggleLogin() {
    setIsLogin((prev) => !prev);
  }

  function setTokenValue(newToken: string | null) {
    setToken(newToken);
  }

  function setUserRoleValue(newRole: string | null) {
    setUserRole(newRole);
  }

  const loginValue: TContext = {
    isLogin: isLogin,
    token: token,
    userRole: userRole,
    toggleLogin: toggleLogin,
    setTokenValue: setTokenValue, // Pass the correct function here
    setUserRoleValue: setUserRoleValue,
  };

  return (
    <LoginContext.Provider value={loginValue}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
