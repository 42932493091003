import React, { useContext } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import LoginContext from "../../store/loginContext"

interface BugReportButtonProps {
  className: string;
  children: React.ReactNode;
}

function BugReportButton({ className, children }: BugReportButtonProps): JSX.Element {
  const { t } = useTranslation();
  const loginCtx = useContext(LoginContext);

  const getUserId = async (): Promise<string> => {
    try {
      if (!loginCtx.token) {
        return "Offline";
      }

      const config = {
        headers: {
          Authorization: `Bearer ${loginCtx.token}`,
        },
      };

      const response = await axios.get('/api/users/user', config);

      return response.data._id;
    } catch (error) {
      console.error('Error getting user ID:', error);
      return "Error";
    }
  }

  const reportBug = async (): Promise<void> => {
    const formId: number = Date.now();
    const pageUrl: string = window.location.href;
    const userId: string = await getUserId();
    const timestamp: string = new Date().toISOString();
    const browserInfo: string = navigator.userAgent;
    const osInfo: string = navigator.platform;
    const screenResolution: string = `${window.screen.width}x${window.screen.height}`;
    const googleFormUrl = `https://docs.google.com/forms/d/e/1FAIpQLScgcmcTQQA9dsC-eYoNS_BaVJ5rn4w-J6ySQhFSQJ7lDBA6Og/viewform?usp=pp_url&entry.1738742194=${formId}`;
    window.open(googleFormUrl, '_blank');

    try {

      const response = await axios.post('/api/users/reportbug', {
        formId,
        userId,
        pageUrl,
        timestamp,
        browserInfo,
        osInfo,
        screenResolution,
      });

    } catch (error) {
      console.error('Error reporting bug:', error);
    }
  };

  return (
    <a href="#" className={className} onClick={reportBug}>
      {children}
      {t("reportABug")}
    </a>
  );
};

export default BugReportButton;
