import { images } from "../../../../constants";
import classes from "./Profile.module.scss";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { IcustomersTable } from "../../../../interfaces/Itable";
import LoginContext from "../../../../store/loginContext"
import { Link } from "react-router-dom";

interface ImageCheckerProps {
  url: string;
}

const ImageChecker: React.FC<ImageCheckerProps> = ({ url }) => {
  const [isImage, setIsImage] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setIsImage(true);
    };
    img.onerror = () => {
      setError('Failed to load image.');
    };
    img.src = url;
  }, [url]);

  return (
    <>
      {isImage ? (
        <img className={classes.avatar} src={url} alt="customer avatar" />
      ) : (
        <img className={classes.avatar} src={images.logo} alt="customer avatar" />
      )}
    </>
  );
};

function Profile() {
  const { t } = useTranslation();
  const [customerData, setCustomerData] = useState<IcustomersTable | null>(null);
  const loginCtx = useContext(LoginContext);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  useEffect(() => {
    async function getUsersInfo() {
      try {
        const apiUrl = "/api/users/user";

        const config = {
          headers: {
            Authorization: `Bearer ${loginCtx.token}`, // Add the authentication token here
          },
        };

        const response = await axios.get(apiUrl, config);
        setCustomerData(response.data);

        loginCtx.setUserRoleValue(response.data.subscription);
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchProfilePicture() {
      console.log('Fetching picture')
      try {
        const apiUrl = "/api/s3/file/profilePicture/picture/${props.customer?.username}";

        const config = {
          headers: {
            Authorization: `Bearer ${loginCtx.token}`, // Add the authentication token here
          },
        };
        const response = await axios.get(apiUrl, config);
        setProfilePicture(response.data.url); // Assuming the response contains the URL of the profile picture
      } catch (error) {
        console.error('Error fetching picture:', error);
      }
    }

    getUsersInfo();
    fetchProfilePicture();
  }, [loginCtx.token]);

  return (
    <div className={classes.profile}>
      <div className={classes.profile__avatar}>
        {profilePicture && <ImageChecker url={profilePicture} />}
      </div>
      <Link
        to="/profile"
        className={classes.profile__item}
      >
        <div className={classes.profile__info}>
          <p className={classes.profile__userName}>{customerData?.username}</p>
          <span className={classes.profile__role}>{customerData?.email?.replace('#google:', '')}</span>
        </div>
      </Link>
    </div>
  );
}

export default Profile;
