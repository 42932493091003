import React, { useEffect, useState } from "react";
import RegisterBox from "../components/register/Register";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import RegisterBetaBox from "../components/register/RegisterBeta";
import NotFound from "./NotFound";


function RegisterBeta() {
  const { code } = useParams();
  const [searchParams] = useSearchParams();

  if (!code) {
    return <NotFound />;
  }
  const mail = searchParams.get("email");
  if (!mail) {
    return <NotFound />;
  }

  return (
    <section>
      <RegisterBetaBox code={code} email={mail} />
    </section>
  );
}

export default RegisterBeta;
