import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import RegisterBeta from "./pages/RegisterBeta";

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Customers = React.lazy(() => import("./pages/Customers"));
const CustomerEdit = React.lazy(() => import("./pages/CustomerEdit"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const BlankPage = React.lazy(() => import("./pages/BlankPage"));
const Library = React.lazy(() => import("./pages/Library"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const ProfileEdit = React.lazy(() => import("./pages/ProfileEdit"));
const Song = React.lazy(() => import("./pages/Song"));
const Forget = React.lazy(() => import("./pages/Forget"));
const ResetPassword = React.lazy(() => import("./pages/Reset"));
const Feedbacks = React.lazy(() => import("./pages/Feedbacks"));

function App() {
  return (
    <div className="app-container">
      <ReactNotifications />
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/:customerId" element={<CustomerEdit />} />
                <Route path="/feedbacks" element={<Feedbacks />} />
                <Route path="/profile" element={<ProfileEdit />} />
                <Route path="/library" element={<Library />} />
                <Route path="/song/:songId" element={<Song />} />
              </Route>
            </Route>
            <Route path="/song/:songId" element={<Song />} />
            <Route path="/register-beta/:code" element={<RegisterBeta />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forget" element={<Forget />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
