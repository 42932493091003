import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import { SidebarContextProvider } from "./store/sidebarContext";
import { LangContextProvider } from "./store/langContext";
import { ThemeContextProvider } from "./store/themeContext";
import { LoginContextProvider } from "./store/loginContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

//axios.defaults.baseURL = "http://134.255.232.82:9001"; // To test without using docker, don't remove the line
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL; // for production, don't remove the line
axios.defaults.headers.post["Content-Type"] = "application/json";


ReactDOM.render(
  <LangContextProvider>
      <LoginContextProvider>
        <ThemeContextProvider>
          <SidebarContextProvider>
              <App />
          </SidebarContextProvider>
        </ThemeContextProvider>
      </LoginContextProvider>
    </LangContextProvider>,
  document.getElementById("root")
);
